import * as amplitude from '@amplitude/analytics-browser';
import * as images from './../../images';
import './index.css';

function Banner() {
    return (
        <div className={'banner-container'}>
            <img src={images.banner_logo} alt={'Logo'} height={114} />
            <span className={'banner-text'}>{'Contacta a '}
                <span className='banner-strong-text'>{'Momentu,'}</span>
                {' y ahorra hasta un '}
                <span className={'banner-strong-text'}>{'20%'}</span>
                {' con la aseguradora que elijas'}</span>
            <div className={'spacer'} />
            <button className={'banner-button'} onClick={() => {
                amplitude.track('contact_with_momentu');
                window.open('https://www.momentu.co/contact', '_blank');
            }}>{'Contactar a Momentu'}</button>
        </div>
    );
}

export default Banner;
