import './index.css';
import * as images from './../../images';

import HubspotForm from './../react-hs'

function FormModal({ onClose, onSubmitted }) {
    return (
        <div className={'modal-container'}>
            <div className={'modal-content'} style={{ paddingRight: 32, paddingLeft: 32 }}>
                <div className={'modal-close-button-container'}>
                    <img style={{ cursor: 'pointer' }} src={images.close}
                        onClick={onClose}
                        alt={'close-button'} height={24} />
                </div>
                <HubspotForm
                    portalId={process.env.REACT_APP_HS_PORTAL_ID}
                    formId={process.env.REACT_APP_HS_FORM_ID}
                    onSubmit={onSubmitted}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />
                {/* <div style={{ width: '100%', display: 'flex', paddingRight: 32, paddingLeft: 32 }}>
                    <FormIllustration />
                    <Form onCompleted={onSubmitted} />
                    <HubspotForm
                        portalId={process.env.REACT_APP_HS_PORTAL_ID}
                        formId={process.env.REACT_APP_HS_FORM_ID}
                        onSubmit={onSubmitted}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                </div> */}
            </div>
        </div>
    );
}

export default FormModal;