import { addDoc, collection } from 'firebase/firestore';
import * as amplitude from '@amplitude/analytics-browser';
import React, { useState, useEffect } from 'react';
import 'typeface-red-hat-text';
import './App.css';
import Banner from './components/banner';
import InfoCard from './components/info';
import FormModal from './components/modal';
import Table from './components/table';
import db from './firebase';
import * as images from './images';

amplitude.init(process.env.REACT_APP_AMPLITUDE_PROJECT_ID, undefined, {
  defaultTracking: false
});


function App() {

  useEffect(() => {
    amplitude.track('page_viewed');
    return () => { };
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [blured, setBlured] = useState(true);
  const data_table = [
    {
      insurer: 'Monterrey',
      consulting: '5.0',
      user_service: '5.0',
      response_time: '5.0',
      electronic_management: '5.0',
      coverage: '4.1',
      cost: '3',
    },
    {
      insurer: 'Mapfre',
      consulting: '2.9',
      user_service: '3.3',
      response_time: '4.3',
      electronic_management: '3.7',
      coverage: '1.0',
      cost: '5',
    },
    {
      insurer: 'Zurich',
      consulting: '2.8',
      user_service: '4.0',
      response_time: '4.6',
      electronic_management: '4.9',
      coverage: '3.9',
      cost: '2',
    },
    {
      insurer: 'Axa',
      consulting: '2.0',
      user_service: '2.7',
      response_time: '4.6',
      electronic_management: '4.5',
      coverage: '2.3',
      cost: '2',
    },
    {
      insurer: 'Metlife',
      consulting: '1.8',
      user_service: '1.3',
      response_time: '3.2',
      electronic_management: '4.7',
      coverage: '4.4',
      cost: '3',
    },
    {
      insurer: 'GNP',
      consulting: '1.8',
      user_service: '1.0',
      response_time: '4.3',
      electronic_management: '2.8',
      coverage: '2.7',
      cost: '1',
    },
    {
      insurer: 'Atlas',
      consulting: '1.0',
      user_service: '4.9',
      response_time: '1.0',
      electronic_management: '4.4',
      coverage: '2.3',
      cost: '2',
    },
    {
      insurer: 'Banorte',
      consulting: '1.7',
      user_service: '2.6',
      response_time: '4.3',
      electronic_management: '1.0',
      coverage: '',
      cost: '5',
    },
    {
      insurer: 'Chubb',
      consulting: '3.1',
      user_service: '2.8',
      response_time: '4.6',
      electronic_management: '4.1',
      coverage: '',
      cost: '4',
    },
    {
      insurer: 'Be por mas',
      consulting: '1.8',
      user_service: '',
      response_time: '9.4',
      electronic_management: '',
      coverage: '5.0',
      cost: '4',
    },
    {
      insurer: 'Inbursa',
      consulting: '1.0',
      user_service: '4.3',
      response_time: '4.3',
      electronic_management: '4.4',
      coverage: '',
      cost: '3',
    },
    {
      insurer: 'Citibanamex',
      consulting: '1.0',
      user_service: '4.8',
      response_time: '2.8',
      electronic_management: '4.9',
      coverage: '',
      cost: '',
    },

  ];

  async function handleSubmit(formData) {
    try {
      // await addDoc(collection(db, "coverage_form_data"), {
      //   ...formData,
      //   date: new Date().toISOString()
      // });
      setBlured(false);
      setShowModal(false);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  return (
    <div className="App">
      <img src={images.Logo} className={'logo'} alt={'Logo'} height={24} />
      <div className={'content'}>
        <InfoCard />
        <Table data={data_table} hasBlur={blured} onTryRemoveBlur={() => {
          amplitude.track('view_all_informacion');
          setShowModal(true);
        }} />
      </div>
      <Banner />
      {showModal && <FormModal onSubmitted={handleSubmit} onClose={() => {
        setShowModal(false);
      }} />}
    </div>
  );
}

export default App;