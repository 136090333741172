import './index.css';
import BlurScreen from './../blur_screen';

function Table({ data, hasBlur = true, onTryRemoveBlur }) {
    const firstThreeElements = data.slice(0, 3);
    const fakeData = [...firstThreeElements, ...firstThreeElements, ...firstThreeElements, ...firstThreeElements];
    function validateCellStyle(value) {
        if (Boolean(value && value !== null && value)) {
            if (value < 2) {
                return { fontWeight: 700 };
            } else if (value >= 2 && value < 3) {
                return { color: '#CAAC19', fontWeight: 700 };
            } else if (value >= 3 && value < 4) {
                return { color: '#C0C229', fontWeight: 700 };
            } else if (value >= 4 && value < 5) {
                return { color: '#76AA40', fontWeight: 700 };
            } else if (value >= 5) {
                return { color: '#259058', fontWeight: 700 };
            }
        }
        return {};
    }
    return (
        <>
            <div className={'table-container'}>
                <table>
                    <thead className="tableHeader">
                        <tr>
                            <th>Aseguradora</th>
                            <th>Resolución a favor del usuario</th>
                            <th>Quejas / Reclamaciones</th>
                            <th>Tiempo de respuesta</th>
                            <th>Gestion Electrónica</th>
                            <th>Cobertura</th>
                            <th>Costo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(hasBlur ? fakeData : data).map((row, i) => (
                            <tr key={`${row.insurer}-${i}`}>
                                <td>{row.insurer}</td>
                                <td style={validateCellStyle(row.consulting)}>{row.consulting}</td>
                                <td style={validateCellStyle(row.user_service)}>{row.user_service}</td>
                                <td style={validateCellStyle(row.response_time)}>{row.response_time}</td>
                                <td style={validateCellStyle(row.electronic_management)}>{row.electronic_management}</td>
                                <td style={validateCellStyle(row.coverage)}>{row.coverage}</td>
                                <td style={validateCellStyle(row.cost)}>{row.cost}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {hasBlur && <BlurScreen />}
                {hasBlur && <div className={'table-blur-button-container'}>
                    <button className={'table-blur-button'} onClick={onTryRemoveBlur}>
                        {'Ver toda la información '}
                    </button>
                </div>}
            </div>
            <div className={'table-container-small'}>
                {(hasBlur ? fakeData : data).map((row, i) => (
                    <div className={'table-card-container'} style={{ ...(i === 0 ? { borderRadius: '16px 16px 0px 0px' } : i === (data.length - 1) ? { borderRadius: '0px 0px 16px 16px' } : {}) }} key={`${row.insurer}-${i}`}>
                        <span className={'table-card-line'}>Aseguradora: <strong>{row.insurer}</strong></span>
                        <span className={'table-card-line'}>Resolución a favor del usuario: <span style={validateCellStyle(row.consulting)}>{row.consulting}</span></span>
                        <span className={'table-card-line'}>Asesorías: <span style={validateCellStyle(row.user_service)}>{row.user_service}</span></span>
                        <span className={'table-card-line'}>Tiempo de respuesta: <span style={validateCellStyle(row.response_time)}>{row.response_time}</span></span>
                        <span className={'table-card-line'}>Gestion Electrónica: <span style={validateCellStyle(row.electronic_management)}>{row.electronic_management}</span></span>
                        <span className={'table-card-line'}>Cobertura: <span style={validateCellStyle(row.coverage)}>{row.coverage}</span></span>
                        <span className={'table-card-line'}>Costo: <span style={validateCellStyle(row.cost)}>{row.cost}</span></span>
                    </div>
                ))}
                {hasBlur && <BlurScreen />}
                {hasBlur && <div className={'table-blur-button-container'}>
                    <button className={'table-blur-button'} onClick={onTryRemoveBlur}>
                        {'Ver toda la información '}
                    </button>
                </div>}
            </div>
        </>
    );
}

export default Table;