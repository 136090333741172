import './index.css';

function InfoCard() {
    return (
        <div className={'info-container'}>
            <h3 className={'info-title'}>{'Comparador de aseguradoras'}</h3>
            <span className={'info-body'}>{'En el competitivo mundo empresarial, garantizar el bienestar de tus empleados es esencial.'}</span>
            <br />
            <br />
            <span className={'info-body'}>{'Pero, ¿cómo saber cuál seguro laboral se ajusta mejor a las necesidades de tu empresa? Nuestra herramienta de comparación de aseguradoras está diseñada precisamente para responder a esa pregunta.'}</span>
            <h3 className={'info-title'} style={{ marginBottom: 16 }}>{'Convenciones'}</h3>
            <div className="progress-container">
                <div className="progress-bar"/>
                <div className="markers">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                </div>
                <div className="labels">
                    <span>Con limitaciones</span>
                    <span>Óptimo</span>
                </div>
            </div>
            <span className={'info-body'}>{'Para comparar eficientemente diferentes seguros laborales, hemos adoptado una escala numérica sencilla del 1 a 5.'}</span>
            <br />
            <span className={'info-body'}>{'Mientras más alto el valor, mejor desempeño de la aseguradora en esa característica.'}</span>
        </div>
    );
}

export default InfoCard;