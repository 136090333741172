import React, { useEffect, useRef, useState } from 'react';

let globalId = 0;

const HubspotForm = ({
  loading,
  onSubmit,
  onReady,
  noScript,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  const id = useRef(globalId++);
  const elRef = useRef(null);

  const createForm = () => {
    if (window.hbspt && elRef.current) {
      const options = {
        ...props,
        target: `#reactHubspotForm${id.current}`,
        onFormSubmit: () => {
          if (onSubmit) {
            onSubmit();
          }
        },
      };
      window.hbspt.forms.create(options);
    } else {
      setTimeout(createForm, 1);
    }
  };

  const findFormElement = () => {
    if (elRef.current) {
      const form = elRef.current.querySelector('iframe');
      if (form) {
        setLoaded(true);
        if (onReady) {
          onReady(form);
        }
      } else {
        setTimeout(findFormElement, 1);
      }
    }
  };

  useEffect(() => {
    if (!window.hbspt && !noScript) {
      const script = document.createElement('script');
      script.defer = true;
      script.onload = () => {
        createForm();
        findFormElement();
      };
      script.src = '//js.hsforms.net/forms/v2.js';
      document.head.appendChild(script);
    } else {
      createForm();
      findFormElement();
    }
  }, []);

  return (
    <div>
      <div
        ref={elRef}
        id={`reactHubspotForm${id.current}`}
        style={{ display: loaded ? 'block' : 'none' }}
      />
      {!loaded && loading}
    </div>
  );
};

export default HubspotForm;
